/**
 * Users
 */
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SWITCH_SUCCESS = "SWITCH_SUCCESS";
export const ADD_USER_FROM_PAGE = "ADD_USER_FROM_PAGE";
export const USER_OVERVIEW_FETCHING = "USER_OVERVIEW_FETCHING";
export const USER_OVERVIEW_FETCHED = "USER_OVERVIEW_FETCHED";
export const USER_OVERVIEW_FETCH_ERROR = "USER_OVERVIEW_FETCH_ERROR";
export const LOCATION_USER_STATUS_FETCHING = "LOCATION_USER_STATUS_FETCHING";
export const LOCATION_USER_STATUS_FETCHED = "LOCATION_USER_STATUS_FETCHED";
export const LOCATION_USER_STATUS_FETCH_ERROR =
  "LOCATION_USER_STATUS_FETCH_ERROR";
export const USER_HOTKEYS_FETCHED = "USER_HOTKEYS_FETCHED";
export const AGENT_CHAT_STARTING = "AGENT_CHAT_STARTING";
export const AGENT_CHAT_STARTED = "AGENT_CHAT_STARTED";
export const AGENT_CHAT_START_ERROR = "AGENT_CHAT_START_ERROR";
export const VALID_TOKEN = "VALID_TOKEN";
export const INVALID_TOKEN = "INVALID_TOKEN";
export const SET_USER_STATUS = "SET_USER_STATUS";
export const USER_PROFILE_FETCHING = "USER_PROFILE_FETCHING";
export const USER_PROFILE_FETCHED = "USER_PROFILE_FETCHED";
export const USER_PROFILE_FETCH_ERROR = "USER_PROFILE_FETCH_ERROR";
export const USER_AVATAR_DELETING = "USER_AVATAR_DELETING";
export const USER_AVATAR_DELETED = "USER_AVATAR_DELETED";
export const USER_AVATAR_DELETE_ERROR = "USER_AVATAR_DELETE_ERROR";
export const USER_AVATAR_SAVED = "USER_AVATAR_SAVED";
export const USER_PROFILE_UPDATING = "USER_PROFILE_UPDATING";
export const USER_PROFILE_UPDATED = "USER_PROFILE_UPDATED";
export const USER_PROFILE_INFO_UPDATED = "USER_PROFILE_INFO_UPDATED";
export const USER_PROFILE_UPDATE_ERROR = "USER_PROFILE_UPDATE_ERROR";
export const USER_PASSWORD_CHANGING = "USER_PASSWORD_CHANGING";
export const USER_PASSWORD_CHANGED = "USER_PASSWORD_CHANGED";
export const USER_PASSWORD_CHANGE_ERROR = "USER_PASSWORD_CHANGE_ERROR";
export const CUSTOMER_USERS_FETCHED = "CUSTOMER_USERS_FETCHED";
export const USER_DELETING = "USER_DELETING";
export const USER_DELETED = "USER_DELETED";
export const USER_DELETED_SUPERADMIN = "USER_DELETED_SUPERADMIN";
export const USER_DELETE_ERROR = "USER_DELETE_ERROR";
export const USER_LOCATIONS_UPDATED = "USER_LOCATIONS_UPDATED";
export const USER_RESTORING = "USER_RESTORING";
export const USER_UPDATING = "USER_UPDATING";
export const USER_UPDATED = "USER_UPDATED";
export const USER_INFO_UPDATED = "USER_INFO_UPDATED";
export const USER_UPDATE_ERROR = "USER_UPDATE_ERROR";
export const USER_TEAMS_UPDATING = "USER_TEAMS_UPDATING";
export const USER_TEAMS_UPDATED = "USER_TEAMS_UPDATED";
export const USER_TEAMS_UPDATE_ERROR = "USER_TEAMS_UPDATE_ERROR";
export const AGENT_SCORES_FETCHING = "AGENT_SCORES_FETCHING";
export const AGENT_SCORES_FETCHED = "AGENT_SCORES_FETCHED";
export const AGENT_SCORES_FETCH_ERROR = "AGENT_SCORES_FETCH_ERROR";
export const LOGGED_OUT = "LOGGED_OUT";
export const ALTERNATE_ACCOUNTS_FETCHING = "ALTERNATE_ACCOUNTS_FETCHING";
export const ALTERNATE_ACCOUNTS_FETCHED = "ALTERNATE_ACCOUNTS_FETCHED";
export const ALTERNATE_ACCOUNTS_FETCH_ERROR = "ALTERNATE_ACCOUNTS_FETCH_ERROR";
export const USER_TOKENS_CLEARED = "USER_TOKENS_CLEARED";
export const USER_TOKENS_CLEARING = "USER_TOKENS_CLEARING";
export const USER_TOKENS_CLEAR_ERROR = "USER_TOKENS_CLEAR_ERROR";

/**
 * Conversations
 */
export const CURRENT_CONVERSATIONS_FETCHING = "CURRENT_CONVERSATIONS_FETCHING";
export const CURRENT_CONVERSATIONS_FETCHED = "CURRENT_CONVERSATIONS_FETCHED";
export const CURRENT_CONVERSATIONS_FETCH_ERROR =
  "CURRENT_CONVERSATIONS_FETCH_ERROR";
export const CONVERSATION_FETCHING = "CONVERSATION_FETCHING";
export const CONVERSATION_FETCHED = "CONVERSATION_FETCHED";
export const CONVERSATION_FETCH_ERROR = "CONVERSATION_FETCH_ERROR";

export const CONVERSATION_ACTIVATE = "CONVERSATION_ACTIVATE";
export const CONVERSATION_SET_USER_IDS = "CONVERSATION_REMOVE_USER_IDS";

export const ALL_TRANSCRIPTS_FETCHING = "ALL_TRANSCRIPTS_FETCHING";
export const ALL_TRANSCRIPTS_FETCHED = "ALL_TRANSCRIPTS_FETCHED";
export const TRANSCRIPT_FETCHING = "TRANSCRIPT_FETCHING";
export const TRANSCRIPT_FETCHED = "TRANSCRIPT_FETCHED";
export const TRANSCRIPT_FETCH_ERROR = "TRANSCRIPT_FETCH_ERROR";

export const MESSAGE_SENDING = "MESSAGE_SENDING";
export const MESSAGE_SENT = "MESSAGE_SENT";
export const MESSAGE_SEND_ERROR = "MESSAGE_SEND_ERROR";
export const MARKED_MESSAGES_READ = "MARKED_MESSAGES_READ";
export const STATUS_COMPOSING = "STATUS_COMPOSING";
export const STATUS_PAUSED = "STATUS_PAUSED";

export const ENDING_CONVERSATION = "ENDING_CONVERSATION";
export const ENDED_CONVERSATION = "ENDED_CONVERSATION";
export const ENDING_CONVERSATION_ERROR = "ENDING_CONVERSATION_ERROR";

export const LEAVING_CONVERSATION = "LEAVING_CONVERSATION";
export const LEFT_CONVERSATION = "LEFT_CONVERSATION";
export const LEAVING_CONVERSATION_ERROR = "LEAVING_CONVERSATION_ERROR";

export const CLOSE_CONVERSATION = "CLOSE_CONVERSATION";
export const VISITOR_CHANNEL_PRESENCE = "VISITOR_CHANNEL_PRESENCE";

export const SHOW_TRANSFER_PANEL = "SHOW_TRANSFER_PANEL";
export const HIDE_TRANSFER_PANEL = "HIDE_TRANSFER_PANEL";
export const TOGGLE_TRANSFER_PANEL = "TOGGLE_TRANSFER_PANEL";
export const REQUESTING_TRANSFER = "REQUESTING_TRANSFER";
export const REQUESTED_TRANSFER = "REQUESTED_TRANSFER";
export const REQUESTED_TRANSFER_ERROR = "REQUESTED_TRANSFER_ERROR";
export const TOGGLE_VIDEO_PANEL = "TOGGLE_VIDEO_PANEL";
export const TOGGLE_EXPLODE_TO_TEXT = "TOGGLE_EXPLODE_TO_TEXT";
export const SET_PRIORITY_MESSAGE_DISPLAY = "SET_PRIORITY_MESSAGE_DISPLAY";
export const TOGGLE_NEW_CONVERSATION_PANEL = "TOGGLE_NEW_CONVERSATION_PANEL";

export const SET_ACTIVE_HOTKEY = "SET_ACTIVE_HOTKEY";
export const AGENT_ACCEPTED = "AGENT_ACCEPTED";
export const AGENT_LEFT = "AGENT_LEFT";

export const TOGGLE_HOTKEY_PANEL = "TOGGLE_HOTKEY_PANEL";
export const CONVERSATION_SET_CRM_NOTES = "CONVERSATION_SET_CRM_NOTES";
export const CONVERSATION_SET_CRM_SEND_TYPE = "CONVERSATION_SET_CRM_SEND_TYPE";
export const RESET_SET_SENT_TO_CRM = "RESET_SET_SENT_TO_CRM";

export const CONVERSATION_SCORES_FETCHING = "CONVERSATION_SCORES_FETCHING";
export const CONVERSATION_SCORES_FETCHED = "CONVERSATION_SCORES_FETCHED";
export const CONVERSATION_SCORES_FETCH_ERROR =
  "CONVERSATION_SCORES_FETCH_ERROR";

export const CONVERSATION_EVENT_TAKEN_OVER = "CONVERSATION_EVENT_TAKEN_OVER";

export const REMOVE_TEMP_MESSAGE = "REMOVE_TEMP_MESSAGE";

/**
 * Glove Compartment
 * @type {string}
 */
export const SET_GC_SEARCH_FILTER = "SET_GC_SEARCH_FILTER";
export const SET_GC_SEARCH_PHRASE = "SET_GC_SEARCH_PHRASE";
export const SET_GC_VEHICLE_DETAILS_SEARCH_PARAMS =
  "SET_GC_VEHICLE_DETAILS_SEARCH_PARAMS";
export const GC_SEARCH_PENDING = "GC_SEARCH_PENDING";
export const GC_SEARCH_SUCCESS = "GC_SEARCH_SUCCESS";
export const GC_SEARCH_ERROR = "GC_SEARCH_ERROR";
export const CALCULATOR_PENDING = "CALCULATOR_PENDING";
export const CALCULATOR_SUCCESS = "CALCULATOR_SUCCESS";
export const CALCULATOR_ERROR = "CALCULATOR_ERROR";

/**
 * Misc
 */
export const SET_CONFIG_FROM_PAGE = "SET_CONFIG_FROM_PAGE";
export const BROWSER_CONFIG_FETCHED = "BROWSER_CONFIG_FETCHED";
export const WINDOW_FOCUS_CHANGE = "WINDOW_FOCUS_CHANGE";
export const CONNECTION_STATUS_CHANGE = "CONNECTION_STATUS_CHANGE";
export const HOLIDAYS_FETCHING = "HOLIDAYS_FETCHING";
export const HOLIDAYS_FETCHED = "HOLIDAYS_FETCHED";
export const HOLIDAYS_FETCH_ERROR = "HOLIDAYS_FETCH_ERROR";
export const REGIONS_FETCHING = "REGIONS_FETCHING";
export const REGIONS_FETCHED = "REGIONS_FETCHED";
export const REGIONS_FETCH_ERROR = "REGIONS_FETCH_ERROR";
export const FB_MARKETPLACE_IMPORTING = "FB_MARKETPLACE_IMPORTING";
export const FB_MARKETPLACE_IMPORT_COMPLETE = "FB_MARKETPLACE_IMPORT_COMPLETE";

/**
 * Pusher
 */
export const CONNECTING_TO_PUSHER = "CONNECTING_TO_PUSHER";
export const CONNECTED_TO_PUSHER = "CONNECTED_TO_PUSHER";
export const JOINED_NOTIFICATION_CHANNEL = "JOINED_NOTIFICATION_CHANNEL";
export const JOINED_ALL_NOTIFICATION_CHANNELS =
  "JOINED_ALL_NOTIFICATION_CHANNELS";
export const LEFT_NOTIFICATION_CHANNEL = "LEFT_NOTIFICATION_CHANNEL";
export const JOINED_CONVERSATION_CHANNEL = "JOINED_CONVERSATION_CHANNEL";
export const LEFT_CONVERSATION_CHANNEL = "LEFT_CONVERSATION_CHANNEL";
export const JOINED_CONTROL_CHANNEL = "JOINED_CONTROL_CHANNEL";

/**
 * Pusher events
 */
export const PUSHER_VISITOR_WAITING = "PUSHER_VISITOR_WAITING";
export const PUSHER_TRANSFER_WAITING = "PUSHER_TRANSFER_WAITING";
export const PUSHER_OFFER_REVOKED = "PUSHER_OFFER_REVOKED";
export const PUSHER_NEW_MESSAGE = "PUSHER_NEW_MESSAGE";
export const PUSHER_NEW_READ_RECEIPTS = "PUSHER_NEW_READ_RECEIPTS";
export const FORCE_REFRESH = "FORCE_REFRESH";

/**
 * Offers
 */
export const ACCEPTING_OFFER = "ACCEPTING_OFFER";
export const ACCEPTED_OFFER = "ACCEPTED_OFFER";
export const ACCEPTING_OFFER_ERROR = "ACCEPTING_OFFER_ERROR";
export const OFFERS_FETCHING = "OFFERS_FETCHING";
export const OFFERS_FETCHED = "OFFERS_FETCHED";
export const OFFERS_FETCH_ERROR = "OFFERS_FETCH_ERROR";
export const INITIAL_OFFER_FETCH_COMPLETED = "INITIAL_OFFER_FETCH_COMPLETED";

/**
 * Locations
 */
export const LOCATION_FETCHING = "LOCATION_FETCHING";
export const LOCATION_FETCHED = "LOCATION_FETCHED";
export const LOCATION_FETCH_ERROR = "LOCATION_FETCH_ERROR";
export const CUSTOMER_LOCATIONS_FETCHING = "CUSTOMER_LOCATIONS_FETCHING";
export const CUSTOMER_LOCATIONS_FETCHED = "CUSTOMER_LOCATIONS_FETCHED";
export const CUSTOMER_LOCATIONS_FETCH_ERROR = "CUSTOMER_LOCATIONS_FETCH_ERROR";
export const ALL_LOCATIONS_FETCHING = "ALL_LOCATIONS_FETCHING";
export const ALL_LOCATIONS_FETCHED = "ALL_LOCATIONS_FETCHED";
export const ALL_LOCATIONS_FETCH_ERROR = "ALL_LOCATIONS_FETCH_ERROR";
export const LOCATION_DEALER_MESSAGES_FETCHING =
  "LOCATION_DEALER_MESSAGES_FETCHING";
export const LOCATION_DEALER_MESSAGES_FETCHED =
  "LOCATION_DEALER_MESSAGES_FETCHED";
export const LOCATION_DEALER_MESSAGES_FETCH_ERROR =
  "LOCATION_DEALER_MESSAGES_FETCH_ERROR";
export const LOCATION_DELETING = "LOCATION_DELETING";
export const LOCATION_DELETED = "LOCATION_DELETED";
export const LOCATION_DELETE_ERROR = "LOCATION_DELETE_ERROR";
export const LOCATION_REACTIVATING = "LOCATION_REACTIVATING";
export const LOCATION_REACTIVATION_ERROR = "LOCATION_REACTIVATION_ERROR";
export const LOCATION_REACTIVATED = "LOCATION_REACTIVATED";
export const LOCATION_DUPLICATING = "LOCATION_DUPLICATING";
export const LOCATION_DUPLICATING_ERROR = "LOCATION_DUPLICATING_ERROR";
export const LOCATION_DUPLICATED = "LOCATION_DUPLICATED";
// used before/after creating/updating a location and represents the entire process (location info, settings, and avatar)
export const LOCATION_UPDATING = "LOCATION_UPDATING";
export const LOCATION_UPDATE_ERROR = "LOCATION_UPDATE_ERROR";
export const LOCATION_UPDATED = "LOCATION_UPDATED";
// location info
export const LOCATION_SAVED = "LOCATION_SAVED";
// location settings info
export const LOCATION_SETTINGS_SAVED = "LOCATION_SETTINGS_SAVED";
// location avatar
export const LOCATION_AVATAR_SAVED = "LOCATION_AVATAR_SAVED";
export const LOCATION_AVATAR_DELETING = "LOCATION_AVATAR_DELETING";
export const LOCATION_AVATAR_DELETED = "LOCATION_AVATAR_DELETED";
export const LOCATION_AVATAR_DELETE_ERROR = "LOCATION_AVATAR_DELETE_ERROR";
export const SET_SELECTED_LOCATION = "SET_SELECTED_LOCATION";
export const LOCATION_FBMP_INVENTORY_FETCHING =
  "LOCATION_FBMP_INVENTORY_FETCHING";
export const LOCATION_FBMP_INVENTORY_FETCHED =
  "LOCATION_FBMP_INVENTORY_FETCHED";
export const LOCATION_FBMP_INVENTORY_ERROR = "LOCATION_FBMP_INVENTORY_ERROR";
export const LOCATION_GBM_RESULTS_FETCHING = "LOCATION_GBM_RESULTS_FETCHING";
export const LOCATION_GBM_RESULTS_FETCHED = "LOCATION_GBM_RESULTS_FETCHED";
export const LOCATION_GBM_RESULTS_ERROR = "LOCATION_GBM_RESULTS_ERROR";
export const LOCATION_GBM_REMOVING = "LOCATION_GBM_REMOVING";
export const LOCATION_GBM_REMOVED = "LOCATION_GBM_REMOVED";
export const LOCATION_GBM_REMOVE_ERROR = "LOCATION_GBM_REMOVE_ERROR";
export const LOCATION_GBM_STARTING = "LOCATION_GBM_STARTING";
export const LOCATION_GBM_STARTED = "LOCATION_GBM_STARTED";
export const LOCATION_GBM_START_ERROR = "LOCATION_GBM_START_ERROR";
export const LOCATION_GBM_MESSAGE_TOGGLING = "LOCATION_GBM_MESSAGE_TOGGLING";
export const LOCATION_GBM_MESSAGE_TOGGLED = "LOCATION_GBM_MESSAGE_TOGGLED";
export const LOCATION_GBM_MESSAGE_ERROR = "LOCATION_GBM_MESSAGE_ERROR";
export const USER_DELETED_FROM_LOCATION = "USER_DELETED_FROM_LOCATION";
export const USER_ADDED_TO_LOCATION = "USER_ADDED_TO_LOCATION";
export const USER_LOCATIONS_UPDATING = "USER_LOCATIONS_UPDATING";
export const USER_LOCATIONS_UPDATE_ERROR = "USER_LOCATIONS_UPDATE_ERROR";

/**
 * Teams
 */
export const CUSTOMER_TEAMS_FETCHING = "CUSTOMER_TEAMS_FETCHING";
export const CUSTOMER_TEAMS_FETCHED = "CUSTOMER_TEAMS_FETCHED";
export const LOCATION_TEAMS_FETCHING = "LOCATION_TEAMS_FETCHING";
export const LOCATION_TEAMS_FETCHED = "LOCATION_TEAMS_FETCHED";
export const LOCATION_TEAMS_FETCH_ERROR = "LOCATION_TEAMS_FETCH_ERROR";
export const TEAM_USER_STATUS_FETCHING = "TEAM_USER_STATUS_FETCHING";
export const TEAM_USER_STATUS_FETCHED = "TEAM_USER_STATUS_FETCHED";
export const TEAM_USER_STATUS_FETCH_ERROR = "TEAM_USER_STATUS_FETCH_ERROR";
export const TEAM_DELETING = "TEAM_DELETING";
export const TEAM_DELETED = "TEAM_DELETED";
export const TEAM_DELETE_ERROR = "TEAM_DELETE_ERROR";
export const TEAM_USERS_UPDATING = "TEAM_USERS_UPDATING";
export const TEAM_USERS_UPDATED = "TEAM_USERS_UPDATED";
export const TEAM_USERS_UPDATE_ERROR = "TEAM_USERS_UPDATE_ERROR";
export const USER_ADDED_TO_TEAM = "USER_ADDED_TO_TEAM";
export const USER_DELETED_FROM_TEAM = "USER_DELETED_FROM_TEAM";
export const TEAM_SAVING = "TEAM_SAVING";
export const TEAM_SAVED = "TEAM_SAVED";
export const TEAM_SAVE_ERROR = "TEAM_SAVE_ERROR";
export const TEAM_HOUR_DELETING = "TEAM_HOUR_DELETING";
export const TEAM_HOUR_DELETED = "TEAM_HOUR_DELETED";
export const TEAM_HOUR_DELETE_ERROR = "TEAM_HOUR_DELETE_ERROR";
export const TEAM_HOUR_ADDING = "TEAM_HOUR_ADDING";
export const TEAM_HOUR_ADDED = "TEAM_HOUR_ADDED";
export const TEAM_HOUR_ADD_ERROR = "TEAM_HOUR_ADD_ERROR";
export const TEAM_CLOSED_DATE_DELETING = "TEAM_CLOSED_DATE_DELETING";
export const TEAM_CLOSED_DATE_DELETED = "TEAM_CLOSED_DATE_DELETED";
export const TEAM_CLOSED_DATE_DELETE_ERROR = "TEAM_CLOSED_DATE_DELETE_ERROR";
export const TEAM_CLOSED_DATE_ADDING = "TEAM_CLOSED_DATE_ADDING";
export const TEAM_CLOSED_DATES_ADDED = "TEAM_CLOSED_DATES_ADDED";
export const TEAM_CLOSED_DATE_ADD_ERROR = "TEAM_CLOSED_DATE_ADD_ERROR";
export const TEAM_POLICY_SAVING = "TEAM_POLICY_SAVING";
export const TEAM_POLICY_SAVED = "TEAM_POLICY_SAVED";
export const TEAM_POLICY_SAVE_ERROR = "TEAM_POLICY_SAVE_ERROR";
export const CALL_CENTER_TEAMS_FETCHED = "CALL_CENTER_TEAMS_FETCHED";

/**
 * Call Center Messages
 */
export const CUSTOMER_DEALER_MESSAGES_FETCHING =
  "CUSTOMER_DEALER_MESSAGES_FETCHING";
export const CUSTOMER_DEALER_MESSAGES_FETCHED =
  "CUSTOMER_DEALER_MESSAGES_FETCHED";
export const CUSTOMER_DEALER_MESSAGES_FETCH_ERROR =
  "CUSTOMER_DEALER_MESSAGES_FETCH_ERROR";
export const CUSTOMER_DEALER_MESSAGE_DELETING =
  "CUSTOMER_DEALER_MESSAGE_DELETING";
export const CUSTOMER_DEALER_MESSAGE_DELETED =
  "CUSTOMER_DEALER_MESSAGE_DELETED";
export const CUSTOMER_DEALER_MESSAGE_DELETE_ERROR =
  "CUSTOMER_DEALER_MESSAGE_DELETE_ERROR";
export const CUSTOMER_DEALER_MESSAGE_ADDING = "CUSTOMER_DEALER_MESSAGE_ADDING";
export const CUSTOMER_DEALER_MESSAGE_SAVED = "CUSTOMER_DEALER_MESSAGE_SAVED";
export const CUSTOMER_DEALER_MESSAGE_SAVE_ERROR =
  "CUSTOMER_DEALER_MESSAGE_SAVE_ERROR";
export const CONVERSATION_DEALER_MESSAGES_FETCHING =
  "CONVERSATION_DEALER_MESSAGES_FETCHING";
export const CONVERSATION_DEALER_MESSAGES_FETCHED =
  "CONVERSATION_DEALER_MESSAGES_FETCHED";
export const CONVERSATION_DEALER_MESSAGES_FETCH_ERROR =
  "CONVERSATION_DEALER_MESSAGES_FETCH_ERROR";

/**
 * Visitors
 */
export const VISITOR_SAVING = "VISITOR_SAVING";
export const VISITOR_SAVED = "VISITOR_SAVED";
export const VISITOR_SAVE_ERROR = "VISITOR_SAVE_ERROR";
export const CRM_SENT = "CRM_SENT";
export const CRM_AUDIT_FAILED = "CRM_AUDIT_FAILED";
export const CRM_AUDIT_REQUESTED = "CRM_AUDIT_REQUESTED";
export const CRM_AUDIT_COMPLETED = "CRM_AUDIT_COMPLETED";
export const CRM_TEMPLATES_FETCHED = "CRM_TEMPLATES_FETCHED";
export const CRM_TEMPLATES_FETCH_ERROR = "CRM_TEMPLATES_FETCH_ERROR";
export const UPDATE_VISITOR_FIELD = "UPDATE_VISITOR_FIELD";
export const UPDATE_VISITOR_FIELD_ERROR = "UPDATE_VISITOR_FIELD_ERROR";
export const VISITOR_PROFILE_UPDATED_REMOTELY =
  "VISITOR_PROFILE_UPDATED_REMOTELY";
export const LOCATION_VISITOR_UNBLACKLIST_PENDING =
  "LOCATION_VISITOR_UNBLACKLIST_PENDING";
export const LOCATION_VISITOR_BLACKLIST_PENDING =
  "LOCATION_VISITOR_BLACKLIST_PENDING";
export const LOCATION_VISITOR_UNBLACKLISTED = "LOCATION_VISITOR_UNBLACKLISTED";
export const LOCATION_VISITOR_BLACKLISTED = "LOCATION_VISITOR_BLACKLISTED";

/**
 * Notifications
 */
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";

/**
 * Send form
 */
export const SEND_FORM_ADD_ATTACHMENT = "SEND_FORM_ADD_ATTACHMENT";
export const SEND_FORM_REMOVE_ATTACHMENT = "SEND_FORM_REMOVE_ATTACHMENT";
export const SEND_FORM_UPDATE_TEXT = "SEND_FORM_UPDATE_TEXT";
export const SEND_FORM_ADD_GARAGE_LINK = "SEND_FORM_ADD_GARAGE_LINK";
export const SEND_FORM_REMOVE_GARAGE_LINK = "SEND_FORM_REMOVE_GARAGE_LINK";

/**
 * Customers
 */
export const ALL_CUSTOMERS_FETCHING = "ALL_CUSTOMERS_FETCHING";
export const ALL_CUSTOMERS_FETCHED = "ALL_CUSTOMERS_FETCHED";
export const ALL_CUSTOMERS_FETCH_ERROR = "ALL_CUSTOMERS_FETCH_ERROR";
export const CUSTOMER_FETCHING = "CUSTOMER_FETCHING";
export const CUSTOMER_FETCHED = "CUSTOMER_FETCHED";
export const CUSTOMER_FETCH_ERROR = "CUSTOMER_FETCH_ERROR";
export const SET_SELECTED_CUSTOMER = "SET_SELECTED_CUSTOMER";
export const CUSTOMER_DELETING = "CUSTOMER_DELETING";
export const CUSTOMER_DELETED = "CUSTOMER_DELETED";
export const CUSTOMER_DELETE_ERROR = "CUSTOMER_DELETE_ERROR";
export const CUSTOMER_SAVED = "CUSTOMER_SAVED";
export const CUSTOMER_UPDATING = "CUSTOMER_UPDATING";
export const CUSTOMER_UPDATED = "CUSTOMER_UPDATED";
export const CUSTOMER_UPDATE_ERROR = "CUSTOMER_UPDATE_ERROR";
export const CUSTOMER_AVATAR_SAVED = "CUSTOMER_AVATAR_SAVED";
export const CUSTOMER_AVATAR_DELETING = "CUSTOMER_AVATAR_DELETING";
export const CUSTOMER_AVATAR_DELETED = "CUSTOMER_AVATAR_DELETED";
export const CUSTOMER_AVATAR_DELETE_ERROR = "CUSTOMER_AVATAR_DELETE_ERROR";
export const CUSTOMER_BANNERS_FETCHING = "CUSTOMER_BANNERS_FETCHING";
export const CUSTOMER_BANNERS_FETCHED = "CUSTOMER_BANNERS_FETCHED";
export const CUSTOMER_BANNERS_FETCH_ERROR = "CUSTOMER_BANNERS_FETCH_ERROR";
export const CUSTOMER_FEEDS_FETCHING = "CUSTOMER_FEEDS_FETCHING";
export const CUSTOMER_FEEDS_FETCHED = "CUSTOMER_FEEDS_FETCHED";
export const CUSTOMER_FEEDS_FETCH_ERROR = "CUSTOMER_FEEDS_FETCH_ERROR";
export const CUSTOMER_HOTKEYS_FETCHING = "CUSTOMER_HOTKEYS_FETCHING";
export const CUSTOMER_HOTKEYS_FETCHED = "CUSTOMER_HOTKEYS_FETCHED";
export const CUSTOMER_HOTKEYS_FETCH_ERROR = "CUSTOMER_HOTKEYS_FETCH_ERROR";
export const CUSTOMER_STARTERS_FETCHING = "CUSTOMER_STARTERS_FETCHING";
export const CUSTOMER_STARTERS_FETCHED = "CUSTOMER_STARTERS_FETCHED";
export const CUSTOMER_STARTERS_FETCH_ERROR = "CUSTOMER_STARTERS_FETCH_ERROR";
export const CUSTOMER_LINKS_FETCHING = "CUSTOMER_LINKS_FETCHING";
export const CUSTOMER_LINKS_FETCHED = "CUSTOMER_LINKS_FETCHED";
export const CUSTOMER_LINKS_FETCH_ERROR = "CUSTOMER_LINKS_FETCH_ERROR";
export const CUSTOMER_CRMS_FETCHING = "CUSTOMER_CRMS_FETCHING";
export const CUSTOMER_CRMS_FETCHED = "CUSTOMER_CRMS_FETCHED";
export const CUSTOMER_CRMS_FETCH_ERROR = "CUSTOMER_CRMS_FETCH_ERROR";
export const CUSTOMER_ACCOLADES_FETCHING = "CUSTOMER_ACCOLADES_FETCHING";
export const CUSTOMER_ACCOLADES_FETCHED = "CUSTOMER_ACCOLADES_FETCHED";
export const CUSTOMER_ACCOLADES_FETCH_ERROR = "CUSTOMER_ACCOLADES_FETCH_ERROR";
export const CUSTOMER_GENERIC_CONTENT_FETCHING =
  "CUSTOMER_GENERIC_CONTENT_FETCHING";
export const CUSTOMER_GENERIC_CONTENT_FETCHED =
  "CUSTOMER_GENERIC_CONTENT_FETCHED";
export const CUSTOMER_GENERIC_CONTENT_FETCH_ERROR =
  "CUSTOMER_GENERIC_CONTENT_FETCH_ERROR";
export const CUSTOMER_BROCHURES_FETCHING = "CUSTOMER_BROCHURES_FETCHING";
export const CUSTOMER_BROCHURES_FETCHED = "CUSTOMER_BROCHURES_FETCHED";
export const CUSTOMER_BROCHURES_FETCH_ERROR = "CUSTOMER_BROCHURES_FETCH_ERROR";
export const CUSTOMER_VIDEOS_FETCHING = "CUSTOMER_VIDEOS_FETCHING";
export const CUSTOMER_VIDEOS_FETCHED = "CUSTOMER_VIDEOS_FETCHED";
export const CUSTOMER_VIDEOS_FETCH_ERROR = "CUSTOMER_VIDEOS_FETCH_ERROR";
export const CUSTOMER_USER_STATUS_FETCHING = "CUSTOMER_USER_STATUS_FETCHING";
export const CUSTOMER_USER_STATUS_FETCHED = "CUSTOMER_USER_STATUS_FETCHED";
export const CUSTOMER_USER_STATUS_FETCH_ERROR =
  "CUSTOMER_USER_STATUS_FETCH_ERROR";
export const CUSTOMER_USER_ACTIVE_CHATS_FETCHING =
  "CUSTOMER_USER_ACTIVE_CHATS_FETCHING";
export const CUSTOMER_USER_ACTIVE_CHATS_FETCHED =
  "CUSTOMER_USER_ACTIVE_CHATS_FETCHED";
export const CUSTOMER_USER_ACTIVE_CHATS_FETCH_ERROR =
  "CUSTOMER_USER_ACTIVE_CHATS_FETCH_ERROR";
export const CUSTOMER_USER_MIGRATED = "CUSTOMER_USER_MIGRATED";
export const CUSTOMER_LOCATION_MIGRATED = "CUSTOMER_LOCATION_MIGRATED";
export const CUSTOMER_LOCATION_MIGRATED_AWAY =
  "CUSTOMER_LOCATION_MIGRATED_AWAY";
export const USER_CUSTOMERS_FETCHING = "USER_CUSTOMER_FETCHING";
export const USER_CUSTOMERS_FETCHED = "USER_CUSTOMER_FETCHED";

/**
 * GPT Review
 */
export const GPT_REVIEWS_FETCHING = "GPT_REVIEW_FETCHING";
export const GPT_REVIEWS_FETCHED = "GPT_REVIEW_FETCHED";
export const GPT_REVIEWS_FETCH_ERROR = "GPT_REVIEWS_FETCH_ERROR";
export const GPT_REVIEW_UPDATING = "GPT_REVIEW_UPDATING";
export const GPT_REVIEW_UPDATED = "GPT_REVIEW_UPDATED";
export const GPT_REVIEW_UPDATE_ERROR = "GPT_REVIEW_UPDATE_ERROR";
export const GPT_PROMPT_VERSIONS_FETCHING = "GPT_PROMPT_VERSIONS_FETCHING";
export const GPT_PROMPT_VERSIONS_FETCHED = "GPT_PROMPT_VERSIONS_FETCHED";
export const GPT_PROMPT_VERSIONS_FETCH_ERROR = "GPT_PROMPT_VERSIONS_FETCH_ERROR";

/**
 * Managed Chat Policies
 */
export const CUSTOMER_MANAGED_CHAT_POLICIES_FETCHING =
  "CUSTOMER_MANAGED_CHAT_POLICIES_FETCHING";
export const CUSTOMER_MANAGED_CHAT_POLICIES_FETCHED =
  "CUSTOMER_MANAGED_CHAT_POLICIES_FETCHED";
export const CUSTOMER_MANAGED_CHAT_POLICY_DELETING =
  "CUSTOMER_MANAGED_CHAT_POLICY_DELETING";
export const CUSTOMER_MANAGED_CHAT_POLICY_DELETED =
  "CUSTOMER_MANAGED_CHAT_POLICY_DELETED";
export const CUSTOMER_MANAGED_CHAT_POLICY_DELETE_ERROR =
  "CUSTOMER_MANAGED_CHAT_POLICY_DELETE_ERROR";
export const CUSTOMER_MANAGED_CHAT_POLICY_SAVING =
  "CUSTOMER_MANAGED_CHAT_POLICY_SAVING";
export const CUSTOMER_MANAGED_CHAT_POLICY_SAVED =
  "CUSTOMER_MANAGED_CHAT_POLICY_SAVED";
export const CUSTOMER_MANAGED_CHAT_POLICY_SAVE_ERROR =
  "CUSTOMER_MANAGED_CHAT_POLICY_SAVE_ERROR";

/**
 * Hotkeys
 */
export const LOCATION_HOTKEYS_FETCHING = "LOCATION_HOTKEYS_FETCHING";
export const LOCATION_HOTKEYS_FETCHED = "LOCATION_HOTKEYS_FETCHED";
export const LOCATION_HOTKEYS_FETCH_ERROR = "LOCATION_HOTKEYS_FETCH_ERROR";
export const HOTKEY_DELETING = "HOTKEY_DELETING";
export const HOTKEY_DELETED = "HOTKEY_DELETED";
export const HOTKEY_DELETE_ERROR = "HOTKEY_DELETE_ERROR";
export const HOTKEY_ADDED = "HOTKEY_ADDED";
export const HOTKEY_SAVING = "HOTKEY_SAVING";
export const HOTKEY_SAVED = "HOTKEY_SAVED";
export const HOTKEY_SAVE_ERROR = "HOTKEY_SAVE_ERROR";
export const HOTKEY_FETCHING = "HOTKEY_FETCHING";
export const HOTKEY_FETCHED = "HOTKEY_FETCHED";
export const HOTKEY_FETCH_ERROR = "HOTKEY_FETCH_ERROR";

/**
 * Banners
 */
export const LOCATION_BANNERS_FETCHING = "LOCATION_BANNERS_FETCHING";
export const LOCATION_BANNERS_FETCHED = "LOCATION_BANNERS_FETCHED";
export const LOCATION_BANNERS_FETCH_ERROR = "LOCATION_BANNERS_FETCH_ERROR";
export const BANNER_DELETING = "BANNER_DELETING";
export const BANNER_DELETED = "BANNER_DELETED";
export const BANNER_DELETE_ERROR = "BANNER_DELETE_ERROR";
export const BANNER_ADDED = "BANNER_ADDED";
export const BANNER_SAVING = "BANNER_SAVING";
export const BANNER_SAVED = "BANNER_SAVED";
export const BANNER_SAVE_ERROR = "BANNER_SAVE_ERROR";
export const BANNER_FETCHING = "BANNER_FETCHING";
export const BANNER_FETCHED = "BANNER_FETCHED";
export const BANNER_FETCH_ERROR = "BANNER_FETCH_ERROR";

/**
 * Feature
 */
export const FEATURE_FETCHING = "FEATURE_FETCHING";
export const FEATURE_FETCHED = "FEATURE_FETCHED";
export const FEATURE_FETCH_ERROR = "FEATURE_FETCH_ERROR";

/**
 * Feeds
 */
export const LOCATION_FEEDS_FETCHING = "LOCATION_FEEDS_FETCHING";
export const LOCATION_FEEDS_FETCHED = "LOCATION_FEEDS_FETCHED";
export const LOCATION_FEEDS_FETCH_ERROR = "LOCATION_FEEDS_FETCH_ERROR";
export const FEED_DELETING = "FEED_DELETING";
export const FEED_DELETED = "FEED_DELETED";
export const FEED_DELETE_ERROR = "FEED_DELETE_ERROR";
export const FEED_ADDED = "FEED_ADDED";
export const FEED_SAVING = "FEED_SAVING";
export const FEED_SAVED = "FEED_SAVED";
export const FEED_SAVE_ERROR = "FEED_SAVE_ERROR";
export const FEED_UPDATING = "FEED_UPDATING";
export const FEED_UPDATED = "FEED_UPDATED";
export const FEED_UPDATE_ERROR = "FEED_UPDATE_ERROR";
export const FEED_FETCHING = "FEED_FETCHING";
export const FEED_FETCHED = "FEED_FETCHED";
export const FEED_FETCH_ERROR = "FEED_FETCH_ERROR";
export const FEED_FIELDS_FETCHING = "FEED_FIELDS_FETCHING";
export const FEED_FIELDS_FETCHED = "FEED_FIELDS_FETCHED";
export const FEED_FIELDS_FETCH_ERROR = "FEED_FIELDS_FETCH_ERROR";
export const FEED_MAPPING_SAVING = "FEED_MAPPING_SAVING";
export const FEED_MAPPING_SAVED = "FEED_MAPPING_SAVED";
export const FEED_MAPPING_SAVE_ERROR = "FEED_MAPPING_SAVE_ERROR";
export const FEED_MAPPING_FETCHING = "FEED_MAPPING_FETCHING";
export const FEED_MAPPING_FETCHED = "FEED_MAPPING_FETCHED";
export const FEED_MAPPING_FETCH_ERROR = "FEED_MAPPING_FETCH_ERROR";

/**
 * Generic Content
 */
export const LOCATION_GENERIC_CONTENT_FETCHING =
  "LOCATION_GENERIC_CONTENT_FETCHING";
export const LOCATION_GENERIC_CONTENT_FETCHED =
  "LOCATION_GENERIC_CONTENT_FETCHED";
export const LOCATION_GENERIC_CONTENT_FETCH_ERROR =
  "LOCATION_GENERIC_CONTENT_FETCH_ERROR";
export const GENERIC_CONTENT_DELETING = "GENERIC_CONTENT_DELETING";
export const GENERIC_CONTENT_DELETED = "GENERIC_CONTENT_DELETED";
export const GENERIC_CONTENT_DELETE_ERROR = "GENERIC_CONTENT_DELETE_ERROR";
export const GENERIC_CONTENT_ADDED = "GENERIC_CONTENT_ADDED";
export const GENERIC_CONTENT_SAVING = "GENERIC_CONTENT_SAVING";
export const GENERIC_CONTENT_SAVED = "GENERIC_CONTENT_SAVED";
export const GENERIC_CONTENT_SAVE_ERROR = "GENERIC_CONTENT_SAVE_ERROR";
export const GENERIC_CONTENT_FETCHING = "GENERIC_CONTENT_FETCHING";
export const GENERIC_CONTENT_FETCHED = "GENERIC_CONTENT_FETCHED";
export const GENERIC_CONTENT_FETCH_ERROR = "GENERIC_CONTENT_FETCH_ERROR";
export const GENERIC_CONTENT_UPDATING = "GENERIC_CONTENT_UPDATING";
export const GENERIC_CONTENT_UPDATED = "GENERIC_CONTENT_UPDATED";
export const GENERIC_CONTENT_UPDATE_ERROR = "GENERIC_CONTENT_UPDATE_ERROR";

/**
 * Starter
 */
export const LOCATION_STARTERS_FETCHING = "LOCATION_STARTERS_FETCHING";
export const LOCATION_STARTERS_FETCHED = "LOCATION_STARTERS_FETCHED";
export const LOCATION_STARTERS_FETCH_ERROR = "LOCATION_STARTERS_FETCH_ERROR";
export const STARTER_DELETING = "STARTER_DELETING";
export const STARTER_DELETED = "STARTER_DELETED";
export const STARTER_DELETE_ERROR = "STARTER_DELETE_ERROR";
export const STARTER_ADDED = "STARTER_ADDED";
export const STARTER_SAVING = "STARTER_SAVING";
export const STARTER_SAVED = "STARTER_SAVED";
export const STARTER_SAVE_ERROR = "STARTER_SAVE_ERROR";
export const STARTER_FETCHING = "STARTER_FETCHING";
export const STARTER_FETCHED = "STARTER_FETCHED";
export const STARTER_FETCH_ERROR = "STARTER_FETCH_ERROR";

/**
 * Link
 */
export const LOCATION_LINKS_FETCHING = "LOCATION_LINKS_FETCHING";
export const LOCATION_LINKS_FETCHED = "LOCATION_LINKS_FETCHED";
export const LOCATION_LINKS_FETCH_ERROR = "LOCATION_LINKS_FETCH_ERROR";
export const LINK_DELETING = "LINK_DELETING";
export const LINK_DELETED = "LINK_DELETED";
export const LINK_DELETE_ERROR = "LINK_DELETE_ERROR";
export const LINK_ADDED = "LINK_ADDED";
export const LINK_SAVING = "LINK_SAVING";
export const LINK_SAVED = "LINK_SAVED";
export const LINK_SAVE_ERROR = "LINK_SAVE_ERROR";
export const LINK_FETCHING = "LINK_FETCHING";
export const LINK_FETCHED = "LINK_FETCHED";
export const LINK_FETCH_ERROR = "LINK_FETCH_ERROR";

/**
 * CRM
 */
export const LOCATION_CRMS_FETCHING = "LOCATION_CRMS_FETCHING";
export const LOCATION_CRMS_FETCHED = "LOCATION_CRMS_FETCHED";
export const LOCATION_CRMS_FETCH_ERROR = "LOCATION_CRMS_FETCH_ERROR";
export const CRM_DELETING = "CRM_DELETING";
export const CRM_DELETED = "CRM_DELETED";
export const CRM_DELETE_ERROR = "CRM_DELETE_ERROR";
export const CRM_ADDED = "CRM_ADDED";
export const CRM_SAVING = "CRM_SAVING";
export const CRM_SAVED = "CRM_SAVED";
export const CRM_SAVE_ERROR = "CRM_SAVE_ERROR";
export const CRM_FETCHING = "CRM_FETCHING";
export const CRM_FETCHED = "CRM_FETCHED";
export const CRM_FETCH_ERROR = "CRM_FETCH_ERROR";

/**
 * Video
 */
export const LOCATION_VIDEOS_FETCHING = "LOCATION_VIDEOS_FETCHING";
export const LOCATION_VIDEOS_FETCHED = "LOCATION_VIDEOS_FETCHED";
export const LOCATION_VIDEOS_FETCH_ERROR = "LOCATION_VIDEOS_FETCH_ERROR";
export const VIDEO_DELETING = "VIDEO_DELETING";
export const VIDEO_DELETED = "VIDEO_DELETED";
export const VIDEO_DELETE_ERROR = "VIDEO_DELETE_ERROR";
export const VIDEO_ADDED = "VIDEO_ADDED";
export const VIDEO_SAVING = "VIDEO_SAVING";
export const VIDEO_SAVED = "VIDEO_SAVED";
export const VIDEO_SAVE_ERROR = "VIDEO_SAVE_ERROR";
export const VIDEO_FETCHING = "VIDEO_FETCHING";
export const VIDEO_FETCHED = "VIDEO_FETCHED";
export const VIDEO_FETCH_ERROR = "VIDEO_FETCH_ERROR";

/**
 * Reports
 */
export const CUSTOMER_DASHBOARD_FETCHING = "CUSTOMER_DASHBOARD_FETCHING";
export const CUSTOMER_DASHBOARD_FETCHED = "CUSTOMER_DASHBOARD_FETCHED";
export const CUSTOMER_DASHBOARD_FETCH_ERROR = "CUSTOMER_DASHBOARD_FETCH_ERROR";
export const LOCATION_DASHBOARD_FETCHING = "LOCATION_DASHBOARD_FETCHING";
export const LOCATION_DASHBOARD_FETCHED = "LOCATION_DASHBOARD_FETCHED";
export const LOCATION_DASHBOARD_FETCH_ERROR = "LOCATION_DASHBOARD_FETCH_ERROR";
export const TEAM_DASHBOARD_FETCHING = "TEAM_DASHBOARD_FETCHING";
export const TEAM_DASHBOARD_FETCHED = "TEAM_DASHBOARD_FETCHED";
export const TEAM_DASHBOARD_FETCH_ERROR = "TEAM_DASHBOARD_FETCH_ERROR";
export const CUSTOMER_SUMMARY_FETCHING = "CUSTOMER_SUMMARY_FETCHING";
export const CUSTOMER_SUMMARY_FETCHED = "CUSTOMER_SUMMARY_FETCHED";
export const CUSTOMER_SUMMARY_FETCH_ERROR = "CUSTOMER_SUMMARY_FETCH_ERROR";
export const LOCATION_SUMMARY_FETCHING = "LOCATION_SUMMARY_FETCHING";
export const LOCATION_SUMMARY_FETCHED = "LOCATION_SUMMARY_FETCHED";
export const LOCATION_SUMMARY_FETCH_ERROR = "LOCATION_SUMMARY_FETCH_ERROR";
export const TEAM_SUMMARY_FETCHING = "TEAM_SUMMARY_FETCHING";
export const TEAM_SUMMARY_FETCHED = "TEAM_SUMMARY_FETCHED";
export const TEAM_SUMMARY_FETCH_ERROR = "TEAM_SUMMARY_FETCH_ERROR";
export const AGENT_SUMMARY_FETCHING = "AGENT_SUMMARY_FETCHING";
export const AGENT_SUMMARY_FETCHED = "AGENT_SUMMARY_FETCHED";
export const AGENT_SUMMARY_FETCH_ERROR = "AGENT_SUMMARY_FETCH_ERROR";
export const CONVO_HISTORY_REPORT_FETCHING = "CONVO_HISTORY_REPORT_FETCHING";
export const CONVO_HISTORY_REPORT_FETCHED = "CONVO_HISTORY_REPORT_FETCHED";
export const CONVO_HISTORY_REPORT_FETCH_ERROR =
  "CONVO_HISTORY_REPORT_FETCH_ERROR";
export const CONVO_HISTORY_REPORT_CSV_FETCHING =
  "CONVO_HISTORY_REPORT_CSV_FETCHING";
export const CONVO_HISTORY_REPORT_CSV_FETCHED =
  "CONVO_HISTORY_REPORT_CSV_FETCHED";
export const CONVO_HISTORY_REPORT_CSV_FETCH_ERROR =
  "CONVO_HISTORY_REPORT_CSV_FETCH_ERROR";
export const SOLD_CUSTOMERS_SUMMARY_FETCHING =
  "SOLD_CUSTOMERS_SUMMARY_FETCHING";
export const SOLD_CUSTOMERS_SUMMARY_FETCHED = "SOLD_CUSTOMERS_SUMMARY_FETCHED";
export const SOLD_CUSTOMERS_SUMMARY_FETCH_ERROR =
  "SOLD_CUSTOMERS_SUMMARY_FETCH_ERROR";
export const SOLD_CUSTOMERS_DETAILS_FETCHING =
  "SOLD_CUSTOMERS_DETAILS_FETCHING";
export const SOLD_CUSTOMERS_DETAILS_FETCHED = "SOLD_CUSTOMERS_DETAILS_FETCHED";
export const SOLD_CUSTOMERS_DETAILS_FETCH_ERROR =
  "SOLD_CUSTOMERS_DETAILS_FETCH_ERROR";
export const SOLD_CUSTOMERS_LOCATION_SUMMARY_FETCHING =
  "SOLD_CUSTOMERS_LOCATION_SUMMARY_FETCHING";
export const SOLD_CUSTOMERS_LOCATION_SUMMARY_FETCHED =
  "SOLD_CUSTOMERS_LOCATION_SUMMARY_FETCHED";
export const SOLD_CUSTOMERS_LOCATION_SUMMARY_FETCH_ERROR =
  "SOLD_CUSTOMERS_LOCATION_SUMMARY_FETCH_ERROR";
export const SOLD_CUSTOMERS_LOCATION_DETAILS_FETCHING =
  "SOLD_CUSTOMERS_LOCATION_DETAILS_FETCHING";
export const SOLD_CUSTOMERS_LOCATION_DETAILS_FETCHED =
  "SOLD_CUSTOMERS_LOCATION_DETAILS_FETCHED";
export const SOLD_CUSTOMERS_LOCATION_DETAILS_FETCH_ERROR =
  "SOLD_CUSTOMERS_LOCATION_DETAILS_FETCH_ERROR";

/**
 * OEM Templates
 */
export const OEM_TEMPLATES_SAVE_ERROR = "OEM_TEMPLATES_SAVE_ERROR";
export const OEM_TEMPLATES_UPDATING = "OEM_TEMPLATES_UPDATING";
export const OEM_TEMPLATES_SAVED = "OEM_TEMPLATES_SAVED";

/**
 * UI
 */
export const TOGGLE_SCORE_PANEL = "TOGGLE_SCORE_PANEL";
export const SCORE_PANEL_DATA_FETCHING = "SCORE_PANEL_DATA_FETCHING";
export const SCORE_PANEL_DATA_FETCHED = "SCORE_PANEL_DATA_FETCHED";
export const SCORE_PANEL_DATA_FETCH_ERROR = "SCORE_PANEL_DATA_FETCH_ERROR";
export const CHANGE_DATE_RANGE = "CHANGE_DATE_RANGE";

/**
 * Vehicle of Interest
 */
export const UPDATE_VEHICLE_OF_INTEREST = "UPDATE_VEHICLE_OF_INTEREST";
export const UPDATE_GARAGE_LINK_LOADER = "UPDATE_GARAGE_LINK_LOADER";

/**
 * OS Garage
 */
export const OS_GARAGE_FETCHING = "OS_GARAGE_FETCHING";
export const OS_GARAGE_FETCHED = "OS_GARAGE_FETCHED";
export const OS_GARAGE_FETCH_ERROR = "OS_GARAGE_FETCH_ERROR";

/**
 * Modals
 */
export const SHOW_SMS_LIMIT_MODAL = "SHOW_SMS_LIMIT_MODAL";
export const HIDE_SMS_LIMIT_MODAL = "HIDE_SMS_LIMIT_MODAL";
export const HANDLE_SMS_LIMIT_MODAL_CANCEL = "HANDLE_SMS_LIMIT_MODAL_CANCEL";
export const HANDLE_SMS_LIMIT_MODAL_ACCEPT = "HANDLE_SMS_LIMIT_MODAL_ACCEPT";
export const SET_SMS_LIMIT_MODAL_ERROR_CODE = "SET_SMS_LIMIT_MODAL_ERROR_CODE";
export const SET_SMS_LIMIT_MESSAGE_TO_SEND = "SET_SMS_LIMIT_MESSAGE_TO_SEND";
export const SET_SMS_LIMIT_MESSAGE_TEMP_ID = "SET_SMS_LIMIT_MESSAGE_TEMP_ID";
