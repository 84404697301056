import { buildFormErrors, redirectToRoute } from 'businessLogic/util';
import { deleteUserFromTeam, addUserToTeam } from './teamActions';
import * as types from '../constants/actionTypes';
import { Action, ErrorObject, StateObject } from '../../typedefs/redux';
import {
  getLoggedInUser,
  getLoggedInUserId,
  getLoggedInUserData,
} from '../../selectors/userSelectors';
import { getActiveLocation } from '../../selectors/locationSelectors';
import { addNotification, addErrorNotification } from '../actions/notificationActions';
import { fetchLocationHotkeys } from '../actions/hotkeyActions';
import { activateConversation } from '../actions/conversationsActions';
import { getUser, getFullName, getUserEmail } from '../../businessLogic/userHelper';
import { disconnectFromPusher } from './pusherActions';
import { FORM_ERROR } from 'final-form';
import {getBugsnag} from "../../../src/app/services";
import { addUserToLocation, deleteUserFromLocation } from './locationActions';

const assign = require('lodash.assign');
const difference = require('lodash.difference');
const without = require('lodash.without');

const bugsnag = getBugsnag();

const FormData = typeof self == 'object' ? self.FormData : window.FormData;

interface UserProfile {
  userId: number;
  firstName: string;
  lastName: string;
  timezone: string;
  locale: string;
  email: string;
  phoneNumber: string;
  avatar?: string;
}

interface UpdateUserProfile extends UserProfile {
  customerId: number;
  username: string;
  isAdmin: boolean;
  dmsId: string;
  isSuperAdmin: boolean;
  locationSelections: {
    label: string;
    value: number;
  }[];
  locationSelectionsOriginal: {
    name: string;
    value: number;
  }[];
  teamIds: {
    name: string;
    value: number;
  }[];
  teamIdsOriginal: {
    name: string;
    value: number;
  }[];
}

interface AddUserProfile extends UpdateUserProfile {
  isSuperAdmin: boolean;
  locationIds: {
    label: string;
    value: number;
  }[];
  teamIds: {
    name: string;
    value: number;
  }[];
}

type IdType = number | string;

export function addUserFromPage(userData: object) {
  return { type: types.ADD_USER_FROM_PAGE, payload: userData };
}

export function fetchUserOverview(): Action {
  return async (dispatch, getState, { APIFactory }) => {
    if (getState().users.get('overviewIsFetching')) {
      return;
    }

    dispatch({ type: types.USER_OVERVIEW_FETCHING });
    const userId = getLoggedInUserId(getState()) as number;

    const api = APIFactory.getInstance(getState());

    const resourceUri = (userId: number): string => {
      return `/user/${userId}/overview`;
    };

    try {
      const response = await api.get(resourceUri(userId), { timeout: 30000 });
      dispatch({ type: types.USER_OVERVIEW_FETCHED, payload: response.data });
    } catch {
      dispatch({ type: types.USER_OVERVIEW_FETCH_ERROR });
    }
  };
}

export function fetchActiveLocationUsersStatus(): Action {
  return (dispatch, getState) => {
    const activeLocation = getActiveLocation(getState());

    if (
      !activeLocation ||
            !activeLocation.get('id') ||
            isUserStatusFetching(getState(), activeLocation.get('id'))
    ) {
      return;
    }

    return dispatch(fetchLocationUserStatus(activeLocation.get('id')));
  };
}

export function fetchLoggedInUserUserStatuses(): Action {
  return (dispatch, getState) => {
    const userData = getLoggedInUserData(getState());
    if (!userData) return;

    const locationIds = userData.get('locationIds');
    const promises = locationIds.map((locationId: number) =>
      dispatch(fetchLocationUserStatus(locationId))
    );
    return Promise.all(promises);
  };
}

export function fetchLocationUserStatus(locationId: number): Action {
  return async (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.LOCATION_USER_STATUS_FETCHING, payload: locationId });
    const api = APIFactory.getInstance(getState());

    try {
      const response = await api.get(`location/${locationId}/agent/status`);
      dispatch({
        type: types.LOCATION_USER_STATUS_FETCHED,
        payload: { locationId, response: response.data },
      });
    } catch {
      dispatch({ type: types.LOCATION_USER_STATUS_FETCH_ERROR, payload: { locationId } });
    }
  };
}

function isUserStatusFetching(state: StateObject, locationId: IdType) {
  return state.locations.hasIn(['userStatusFetching', locationId]);
}

export function fetchHotkeys(): Action {
  return async (dispatch, getState) => {
    const state = getState();
    let promises;

    try {
      promises = getLoggedInUserData(state)
        .get('locationIds')
        .map((locationId: number) => dispatch(fetchLocationHotkeys(locationId)));
    } catch (e) {
      // log the user state to BugSnag
      const error = 'Could not get location hot keys.';

      if (process.env.BABEL_ENV !== 'mocha') {
        bugsnag.notify(new Error(error), {
          metaData: {
            errorInfo: {
              userState: state.users,
            },
          },
        });
      }

      promises = [];
    }

    await Promise.all(promises);
    dispatch({ type: types.USER_HOTKEYS_FETCHED });
  };
}

/**
 * Start internal conversation
 * @param userId
 * @param [locationId] locationId for conversation. if not provided, it uses active conversation's location
 */
export function startAgentToAgentConversation(userId: IdType, locationId: number): Action {
  return async (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.AGENT_CHAT_STARTING, payload: userId });
    locationId = locationId || getActiveLocation(getState()).get('id');
    const api = APIFactory.getInstance(getState());

    try {
      const response = await api.post('conversation/agent/start', { toUserId: userId });
      dispatch(
        agentChatStarted(
          userId,
          response.data.channel,
          locationId,
          getLoggedInUserId(getState())
        )
      );
      dispatch(activateConversation(response.data.channel));
    } catch (e) {
      dispatch({ type: types.AGENT_CHAT_START_ERROR, payload: { userId } });
      const targetName = getFullName(getUser(getState(), userId));
      dispatch(
        addNotification({
          level: 'error',
          title: 'Error',
          message: `There was an error starting a conversation with ${targetName}. ${e.toString()}`,
        })
      );
    }
  };
}

function agentChatStarted(
  userId: IdType,
  channel: string,
  locationId: IdType,
  loggedInUserId: IdType
) {
  return {
    type: types.AGENT_CHAT_STARTED,
    payload: { userId, channel, locationId, loggedInUserId },
  };
}

export function validateToken(): Action {
  return async (dispatch, getState, { APIFactory }) => {
    const api = APIFactory.getInstance(getState());
    try {
      await api.post('auth/token/validate');
      dispatch({ type: types.VALID_TOKEN });
    } catch (e) {
      // only reject if it's an actual validation failed response from the API
      if (isAuthError(e)) {
        dispatch({ type: types.INVALID_TOKEN });
        throw new Error();
      }
      // ignore anything else that's not a 401/unauthorized response from the API
    }
  };
}

function isAuthError(e: ErrorObject) {
  if (e && e.response && e.response.status === 401) return true;
  return false;
}

export function setStatus(statusId: number): Action {
  return async (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.SET_USER_STATUS, payload: { statusId } });
    const userId = getLoggedInUserId(getState());
    const api = APIFactory.getInstance(getState());

    try {
      return await api.put(`user/${userId}/status`, { statusId });
    } catch {
      /* ignore error */
      return;
    }
  };
}

export function fetchProfile(userId: IdType): Action {
  return async (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.USER_PROFILE_FETCHING });
    const api = APIFactory.getInstance(getState());

    try {
      const response = await api.get(`user/${userId}/profile`);
      dispatch({ type: types.USER_PROFILE_FETCHED, payload: { response } });
    } catch {
      dispatch({ type: types.USER_PROFILE_FETCH_ERROR });
    }
  };
}

export function removeAvatar(userId: IdType): Action {
  return async (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.USER_AVATAR_DELETING, payload: { userId } });
    const api = APIFactory.getInstance(getState());

    try {
      await api.delete(`/user/${userId}/avatar`);
      dispatch({ type: types.USER_AVATAR_DELETED, payload: { userId } });
    } catch {
      dispatch({ type: types.USER_AVATAR_DELETE_ERROR, payload: { userId } });
      dispatch(addErrorNotification('Error deleting user avatar.'));
    }
  };
}

export function updateUserProfile({
  userId,
  firstName,
  lastName,
  timezone,
  email,
  phoneNumber,
  avatar,
  locale,
}: UserProfile): Action {
  return async (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.USER_PROFILE_UPDATING });
    const api = APIFactory.getInstance(getState());
    const payload = {
      firstName,
      lastName,
      timezone,
      email,
      phoneNumber,
      locale,
    };
    try {
      await api.patch(`/user/${userId}/profile`, payload);
      dispatch({
        type: types.USER_PROFILE_INFO_UPDATED,
        payload: assign({}, payload, { id: userId }),
      });
    } catch (error) {
      dispatch({ type: types.USER_PROFILE_UPDATE_ERROR });
      return {[FORM_ERROR]: 'Error saving user profile.'};
    }

    if (avatar && avatar[0]) {
      try {
        await saveAvatar(api, dispatch, userId, avatar);
      } catch (error) {
        dispatch({ type: types.USER_PROFILE_UPDATE_ERROR });
        return {[FORM_ERROR]: 'Error saving avatar.'};
      }
    }
    return undefined;
  };
}

async function saveAvatar(api: any, dispatch: any, userId: IdType, avatar: any) {
  const data = new FormData();

  data.append('avatar', avatar[0]);
  const response = await api.post(`/user/${userId}/avatar`, data);
  dispatch({
    type: types.USER_AVATAR_SAVED,
    payload: { userId, avatar: response.data.avatar, avatarType: 'user' },
  });
}

export function changePassword(
  userId: number,
  password: string,
  passwordConfirmation: string
): Action {
  return async (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.USER_PASSWORD_CHANGING, payload: { userId } });

    const api = APIFactory.getInstance(getState());
    try {
      await api.patch(`/user/${userId}/profile`, {
        password,
        password_confirmation: passwordConfirmation,
      });

      dispatch({ type: types.USER_PASSWORD_CHANGED, payload: { userId } });
      dispatch(
        addNotification({
          level: 'success',
          title: 'Success',
          message: 'Password has been changed.',
        })
      );
    } catch {
      dispatch({ type: types.USER_PASSWORD_CHANGE_ERROR, payload: { userId } });
      dispatch(addErrorNotification('Error changing password.'));
    }
  };
}

export function fetchCustomerUsers(customerId: number): Action {
  return async (dispatch, getState, { APIFactory }) => {
    const api = APIFactory.getInstance(getState());
    const response = await api.get(`/customer/${customerId}/users`);

    dispatch({
      type: types.CUSTOMER_USERS_FETCHED,
      payload: { users: response.data.users },
    });
  };
}

export function deleteUser(userId: number): Action {
  return async (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.USER_DELETING, payload: { userId } });
    const api = APIFactory.getInstance(getState());
    const loggedInUser = getLoggedInUser(getState());

    try {
      await api.delete(`/admin/user/${userId}`);
      if (loggedInUser.get('isSuperAdmin') === true) {
        dispatch({ type: types.USER_DELETED_SUPERADMIN, payload: { userId } });
      } else {
        dispatch({ type: types.USER_DELETED, payload: { userId } });
      }
    } catch (e) {
      dispatch({ type: types.USER_DELETE_ERROR, payload: { userId } });
      dispatch(addErrorNotification('Error deactivating user.'));
    }
  };
}

export function updateUser({
  userId,
  customerId,
  username,
  firstName,
  lastName,
  timezone,
  email,
  phoneNumber,
  isAdmin,
  isSuperAdmin,
  avatar,
  dmsId,
  locale,
  locationSelections,
  locationSelectionsOriginal,
  teamIds,
  teamIdsOriginal,
}: UpdateUserProfile): Action {
  return async (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.USER_UPDATING, payload: { userId } });
    const api = APIFactory.getInstance(getState());

    const locations = locationSelections.map(({ value, label }) => ({ id: value, name: label}));

    const payload = {
      username,
      customerId,
      firstName,
      lastName,
      timezone,
      email,
      phoneNumber,
      isAdmin,
      isSuperAdmin,
      dmsId,
      locale,
      locations,
    };

    try {
      await api.patch(`/admin/user/${userId}`, payload);
      dispatch({
        type: types.USER_INFO_UPDATED,
        payload: assign({}, payload, { id: userId }),
      });
    } catch (error) {
      dispatch({ type: types.USER_UPDATE_ERROR, payload: { userId, error } });
      return buildFormErrors('Error saving user info.', error.response);
    }

    if (avatar && avatar[0]) {
      try {
        await saveAvatar(api, dispatch, userId, avatar);
      } catch (error) {
        dispatch({ type: types.USER_UPDATE_ERROR, payload: { userId, error } });
        return buildFormErrors('Error saving avatar.', error.response);
      }
    }

    try {
      await updateUserLocations(
        userId,
        locationSelectionsOriginal ? locationSelectionsOriginal.map(({ value }) => value) : [],
        locationSelections ? locationSelections.map(({ value }) => value) : [],
      )(dispatch, getState, { APIFactory });
    } catch (error) {
      return buildFormErrors('Error saving location.', error.response);
    }

    try {
      await updateUserTeams(
        userId,
        teamIdsOriginal ? teamIdsOriginal.map(({ value }) => value) : [],
        teamIds ? teamIds.map(({ value }) => value) : []
      )(dispatch, getState, { APIFactory });
    } catch (error) {
      return buildFormErrors('Error saving teams.', error.response);
    }

    return;
  };
}

export function addUser({
  customerId,
  username,
  firstName,
  lastName,
  timezone,
  email,
  phoneNumber,
  isAdmin,
  isSuperAdmin,
  avatar,
  dmsId,
  teamIds,
  locationSelections,
  locale,
}: AddUserProfile): Action {
  return async (dispatch, getState, { APIFactory }) => {
    let userId: number;
    dispatch({ type: types.USER_UPDATING });
    const api = APIFactory.getInstance(getState());
    const userPayload = {
      username,
      firstName,
      lastName,
      dmsId,
      locale,
      timezone,
      email,
      phoneNumber,
      isAdmin,
      isSuperAdmin,
      password: 'Conversations1234',
      password_confirmation: 'Conversations1234',
    };

    try {
      const response = await api.post('/admin/user', userPayload); // save user
      userId = response.data.id;
      const actionPayload = assign({}, userPayload, { id: userId, locations: [] });
      delete actionPayload.password;
      delete actionPayload.password_confirmation;
      dispatch({ type: types.USER_INFO_UPDATED, payload: actionPayload });

      // save avatar if it exists
      if (avatar && avatar[0]) {
        await saveAvatar(api, dispatch, userId, avatar);
      } else {
        // since they didn't upload an avatar, we need to use the customer's avatar instead
        const customerAvatar = getState().customers.getIn([
          'customers',
          customerId,
          'avatar',
        ]);
        dispatch({
          type: types.USER_AVATAR_SAVED,
          payload: { userId, avatar: customerAvatar, avatarType: 'customer' },
        });
      }

      // save any locations, if selected
      if (locationSelections && locationSelections.length) {
        try {
          await updateUserLocations(
            userId,
            [],
            locationSelections.map(
              ({ value }) => value
            ))(
            dispatch,
            getState,
            { APIFactory }
          );
        } catch (error) {
          return {[FORM_ERROR]: 'Error saving locations.'};
        }
      }

      // save any teams, if selected
      if (teamIds && teamIds.length) {
        try {
          await updateUserTeams(
            userId,
            [],
            teamIds.map(
              ({ value }) => value
            ))(
            dispatch,
            getState,
            { APIFactory }
          );
        } catch (error) {
          return {[FORM_ERROR]: 'Error saving teams.'};
        }
      }

      try {
        const userFetch = dispatch(fetchCustomerUsers(customerId));
        await userFetch;
      } catch (error) {
        return {[FORM_ERROR]: 'Error fetching users.'};
      }

      dispatch({ type: types.USER_UPDATED, payload: { userId } });
      setTimeout(() => redirectToRoute(`/admin/users`), 3000);

      return undefined;
    } catch (error) {
      dispatch({ type: types.USER_UPDATE_ERROR });
      return {[FORM_ERROR]: 'Error saving teams.'};
    }
  };
}

export function updateUserTeams(
  userId: number,
  existingTeamIds: Array<number>,
  newTeamIds: Array<number>
): Action {
  return async (dispatch, getState, { APIFactory }) => {
    const toBeDeleted = without(existingTeamIds, ...newTeamIds);
    const toBeAdded = difference(newTeamIds, existingTeamIds);
    const api = APIFactory.getInstance(getState());

    const deleteUserPromises = toBeDeleted.map((teamId: number) =>
      deleteUserFromTeam(api, dispatch, teamId, userId)
    );
    const addUserPromises = toBeAdded.map((teamId: number) =>
      addUserToTeam(api, dispatch, teamId, userId)
    );

    dispatch({ type: types.USER_TEAMS_UPDATING, payload: { userId } });

    try {
      await Promise.all([...deleteUserPromises, ...addUserPromises]);
      dispatch({ type: types.USER_TEAMS_UPDATED, payload: { userId } });
    } catch (error) {
      dispatch({ type: types.USER_TEAMS_UPDATE_ERROR, payload: { userId } });
      dispatch(addErrorNotification('Error updating user teams.'));
      throw error;
    }
  };
}

export function updateUserLocations(
  userId: number,
  existingLocationId: Array<number>,
  newLocationId: Array<number>
): Action {
  return async (dispatch, getState, { APIFactory }) => {
    const toBeDeleted = without(existingLocationId, ...newLocationId);
    const toBeAdded = difference(newLocationId, existingLocationId);
    const api = APIFactory.getInstance(getState());

    const deleteUserPromises = toBeDeleted.map((location: number) =>
      deleteUserFromLocation(api, dispatch, location, userId)
    );
    const addUserPromises = toBeAdded.map((location: number) =>
      addUserToLocation(api, dispatch, location, userId)
    );

    try {
      await Promise.all([...deleteUserPromises, ...addUserPromises]);
    } catch (error) {
      dispatch({ type: types.USER_LOCATIONS_UPDATE_ERROR, payload: { userId }});
      dispatch(addErrorNotification('Error updating user location.'));
      throw error;
    }
  };
}

export function resendActivationEmail(username: string, userId: number): Action {
  return async (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.USER_PASSWORD_CHANGING, payload: { userId } });
    const api = APIFactory.getInstance(getState());
    try {
      await api.post(`/auth/password/token?username=${username}&id=${userId}&type=new`);

      dispatch({ type: types.USER_PASSWORD_CHANGED, payload: { userId } });
      const email = getUserEmail(getUser(getState(), userId));
      dispatch(
        addNotification({
          level: 'success',
          title: 'Success',
          message: `The Activation email for ${email} has been resent.`,
        })
      );
    } catch {
      dispatch({ type: types.USER_PASSWORD_CHANGE_ERROR, payload: { userId } });
      dispatch(addErrorNotification('Error sending activation email.'));
    }
  };
}

export function fetchAverageScores(userId: number): Action {
  return async (dispatch, getState, { APIFactory }) => {
    dispatch({ type: types.AGENT_SCORES_FETCHING, payload: { userId } });
    const api = APIFactory.getInstance(getState());

    try {
      const response = await api.get(`/agent/${userId}/scores`);
      dispatch({
        type: types.AGENT_SCORES_FETCHED,
        payload: { userId, scores: response.data },
      });
    } catch {
      dispatch({ type: types.AGENT_SCORES_FETCH_ERROR, payload: { userId } });
    }
  };
}

export function ssoLogin(userToken: string, userId: number): Action {
  return async (dispatch) => {
    const userPayload = {token: userToken, id: userId};
    dispatch({
      type: types.LOGIN_SUCCESS,
      payload: userPayload,
    });
  };
}

export function setUserToken(id?: IdType, token?: string) {
  return {
    type: types.LOGIN_SUCCESS,
    payload: { id, token },
  };
}

export function requestPasswordReset(username: string): Action {
  return async (dispatch, getState, { APIFactory }) => {
    const api = APIFactory.getInstance(getState());
    const response = await api.post(`/auth/password/token`, {
      username,
      type: 'existing',
      site: 'web',
    });

    if (response.data.response !== 'success') throw Error();
  };
}

export function resetPassword(token: string, username: string, password: string): Action {
  return async (dispatch, getState, { APIFactory }) => {
    const api = APIFactory.getInstance(getState());
    const response = await api.post(`/auth/password/reset`, {
      token,
      username,
      password,
      // eslint-disable-next-line @typescript-eslint/camelcase
      password_confirmation: password,
    });

    if (response.data.response !== 'success') throw Error(response.data);
  };
}

export function loggedOut() {
  return { type: types.LOGGED_OUT };
}

export function ejectUser() {
  setUserToken(undefined, undefined);
  disconnectFromPusher();
  return loggedOut();
}

export function fetchUserAccountsByAuth0Id(): Action {
  return async (dispatch, getState, { APIFactory }) => {
    const api = APIFactory.getInstance(getState());
    dispatch({ type: types.ALTERNATE_ACCOUNTS_FETCHING });

    try {
      const response = await api.get('/user/users-by-auth0-id');

      dispatch({
        type: types.ALTERNATE_ACCOUNTS_FETCHED,
        payload: response.data,
      });

      return response.data;
    } catch {
      dispatch({ type: types.ALTERNATE_ACCOUNTS_FETCH_ERROR });
    }
  };
}

export function clearUserTokens(userId: number): Action {
  return async (dispatch, getState, { APIFactory }) => {
    const api = APIFactory.getInstance(getState());
    dispatch({type: types.USER_TOKENS_CLEARING});
    try {
      await api.delete('/user/' + userId + '/tokens');
      dispatch({type: types.USER_TOKENS_CLEARED});
      dispatch(
        addNotification({
          level: 'success',
          title: 'Success',
          message: `This user's access tokens have been reset.`,
        })
      );
    } catch {
      dispatch({type: types.USER_TOKENS_CLEAR_ERROR});
      dispatch(addErrorNotification('Error resetting this user\'s access tokens'));
    }
  };
}